import authService from '../../../services/auth';

export default {
  name: 'login',
  data() {
    return {
      errorMsg: '',
      loginProcessing: false,

      loginForm: null,
      formRules: null
    };
  },
  computed: {},
  methods: {
    // Normal Login with email password.
    async onLoginSubmit() {
      try {
        this.errorMsg = '';

        // Validate Form
        let isValidData = await this.validateForm(this.$refs.loginForm);
        if (!isValidData) return;

        this.loginProcessing = true;

        let result = await authService.login(this.loginForm);

        if (result.data.status == 200) {
          this.handleNewSession(result.data);
        } else if (result.data.status == 401) {
          this.errorMsg = result.data.message;
        } else if (result.data.status == 403) {
          this.errorMsg = result.data.message;
        }

        this.loginProcessing = false;
      } catch (err) {
        this.loginProcessing = false;
        this.errorMsg = `Something's wrong. Please contact our support team.`;
        console.error(err);
      }
    }
  },

  created() {
    // Read form and rules from mixin
    this.loginForm = this.getLoginForm();
    this.formRules = this.getLoginFormRules();

    if (this.$route.query['shopify-session-token']) {
      this.loginProcessing = true;
      setTimeout(() => {
        this.handleShopifyLogin();
      }, 1000);
    } else if (this.$route.query.message && this.$route.query.message != '') {
      this.errorToast(this.$route.query.message);
    } else if (this.$route.query.successMsg && this.$route.query.successMsg != '') {
      this.successToast(this.$route.query.successMsg, 6000);
    }
  }
};
